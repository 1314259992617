/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

@import '~bootstrap-icons/font/bootstrap-icons.css';

@import 'src/variables';

.card {
  border-style: solid;
  border-width: 1px;
  border-color: $border-color;

  border-radius: 2px;
}

.avatar {
  border-style: solid;
  border-width: 1px;
  border-color: $border-color-dark;
  border-radius: 50%;
}

.btn.btn-primary {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  background-color: $light-blue;
  border-color: $light-blue;

  &:hover {
    background-color: $light-blue-shadow;
    border-color: $light-blue-shadow-more;
  }

  &:active,
  &.open {
    background-color: $light-blue-shadow-more !important;
    border-color: $light-blue-shadow-more !important;
  }

  i[class^='bi-'] svg {
    height: 24px;
    font-size: 24px;
  }
}

.borders {
  border-width: $border-width-std;
  border-style: solid;
  border-color: $border-color;

  &.only-borders-top {
    border-width: 0;
    border-top-width: $border-width-std;
  }

  &.only-borders-right {
    border-width: 0;
    border-right-width: $border-width-std;
  }

  &.only-borders-bottom {
    border-width: 0;
    border-bottom-width: $border-width-std;
  }

  &.only-borders-left {
    border-width: 0;
    border-left-width: $border-width-std;
  }
}

i[class^='bi-'] {
  display: inline-flex !important;
  height: auto !important;
  width: auto !important;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

app-course-content {
  img {
    max-width: 100%;
  }
}

app-course-results,
app-informative-activity,
app-seminar,
app-assignment,
app-writing {
  table {
    th {
      // background-color: $light-blue;
      // color: white;
      text-align: left;
    }

    th,
    td {
      border-style: solid;
      border-color: $border-color;
      border-width: $border-width-std;
      padding: $table-cell-padding;
    }

    tr:nth-child(even) td {
      background-color: $light-light-light-gray;
    }

    tr.separated td {
      border-top-width: $border-width-l;
      border-top-color: $border-color-dark;
    }
  }
}

.custom-text {
  ul {
    margin-bottom: 0;
    padding-left: $mrgn-xl;

    &.main-list {
      > li:not(:last-child) {
        margin-bottom: $mrgn-m;
      }
    }

    li {
      margin-top: $mrgn-s;
    }
  }

  .decimal-list {
    list-style: decimal;
  }

  .alpha-list {
    list-style: lower-alpha;
  }

  .circle-list {
    list-style: disc;
  }

  .center-text {
    text-align: center;
  }

  .link-cell {
    display: block;
    text-decoration: none;
  }
}

nav.responsive-nav {
  &.equal-widths {
    a {
      flex: 1 1 0px !important;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  a {
    flex-grow: 1;
    text-align: center;
  }

  i[class^='bi-'] {
    font-size: large;
  }
}

@media screen and (max-width: $sm) {
  nav.responsive-nav a span {
    display: none !important;
  }
}

@media screen and (min-width: $sm) {
  nav.responsive-nav a bi {
    display: none !important;
  }
}
