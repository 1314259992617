$sm: 767.98px;
$xs: 575.98px;
$md: 991.98px;
$lg: 1199.98px;

$green: #3eb61f;
$green-select: #3db61f5d;
$green-background: #3db61fc4;

$light-blue: #00b0f0;

$light-blue-shadow: #00a3df;
$light-blue-shadow-more: #0091c5;

$light-blue-select: #00b0f04b;

$red: rgb(192, 0, 0);
$red-select: rgba(192, 0, 0, 0.274);
$red-background: rgba(192, 0, 0, 0.644);

$yellow: rgb(199, 202, 0);
$yellow-background: rgba(199, 202, 0, 0.644);

$notification-red: rgb(216, 88, 88);

$light-gray: #636363;
$light-light-gray: #919191;

$light-light-light-gray: #dddddd63;

$paper-background: #f8f8f8;

$border-color: #b9b9b9;
$border-color-dark: #474747da;

$border-width-std: 1px;
$border-width-l: 2px;

$border-radius-s: 2px;
$border-radius-m: 4px;

$border-std: $border-width-std solid $border-color;

$mrgn-xs: 2px;
$mrgn-s: 5px;
$mrgn-m: 10px;
$mrgn-l: 20px;
$mrgn-xl: 25px;

$award-gold: #d4af37;
$grid-gap: 20px;

$toolbar-height: 50px;
$toolbar-height-sm: 50px;

$btn-height: 30px;
$btn-height-sm: 35px;

$course-activities-width: 70px;

$webkit-box-shadow-std: 0 3px 1px -2px rgb(0 0 0 / 20%),
  0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
$box-shadow-std: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
  0 1px 5px 0 rgb(0 0 0 / 12%);

$grade-color-failed: rgb(255, 115, 115);
$grade-color-complementary: rgb(230, 220, 88);
$grade-color-passed: rgb(95, 230, 106);

$table-cell-padding: 8px;

$form-color-background: rgb(208 208 208 / 16%);
